<template>
  <v-container id="new-event-forms-view" tag="section">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-card>
          <v-card-title>{{ $t("user.profile") }}</v-card-title>
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field autofocus outlined dense id="fname" :label="$t('user.first.name')" v-model="user.first_name"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field outlined dense v-model="user.last_name" :label="$t('user.last.name')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="6">
                <validation-provider v-slot="{ errors }" name="username" rules="required|username|unique" :debounce="200">
                  <v-text-field outlined dense v-model="user.username" :label="$t('user.login')" :error-messages="errors"
                    required hide-details="auto"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined dense v-model="user.name" :label="$t('user.display.name')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <validation-provider v-slot="{ errors }" name="email" rules="email" :debounce="200">
                  <v-text-field outlined dense v-model="user.email" :label="$t('user.email')" :error-messages="errors"
                    hide-details="auto"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="4">
                <validation-provider v-slot="{ errors }" name="phone" rules="phone">
                  <v-text-field outlined dense v-model="user.phone" :label="$t('user.phone')" :error-messages="errors"
                    hide-details="auto"></v-text-field>
                </validation-provider>
              </v-col>

              <!-- <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules=""
                  :debounce="200"
                >
                  <v-text-field
                  v-if="false"
                    outlined
                    dense
                    v-model="user.password"
                    :label="$t('user.password')"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-title>{{ $t("user.informations") }}</v-card-title>
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" sm="7">
                <v-text-field outlined dense v-model="user.organization" :label="$t('user.organization')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field outlined dense v-model="user.job_title" :label="$t('user.job_title')"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="user.bio" :label="$t('user.bio')"
                  hide-details="auto"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>{{ $t("user.rights") }}</v-card-title>
          <v-card-text class="pb-0">
            <v-row>
              <v-checkbox class="ml-5" hide-details="auto" v-model="user.is_superuser" :label="$t('user.admin')">
              </v-checkbox>
              <v-checkbox class="ml-5" hide-details="auto" v-model="user.is_dispatcher" :label="$t('user.dispatcher')">
              </v-checkbox>
              <v-checkbox class="ml-5" hide-details="auto" v-model="user.is_patrol"
                :label="$t('user.patrol')"></v-checkbox>
              <v-checkbox class="ml-5" hide-details="auto" v-model="user.is_servicer"
                :label="$t('user.servicer')"></v-checkbox>
              <v-checkbox class="ml-5" dense hide-details="auto" v-model="user.is_external"
                :label="$t('user.external')"></v-checkbox>
            </v-row>
          </v-card-text>
          <!-- <user-groups-editor class="ma-5" :user="user"></user-groups-editor> -->
          <app-card-actions>
            <cancel-btn @click="$router.back()" />
            <apply-btn class="mx-1" :disabled="invalid" default type="submit"/>
            <destructive-btn class="mx-1" v-if="user.id && user.is_active" @click="block" :text="$t('btn.user.block')"/>
            <destructive-btn class="mx-1" v-if="user.id && !user.is_active" @click="unblock" :text="$t('btn.user.unblock')"/>
          </app-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import _ from "lodash"
import { required, email } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"

import UserService from "@/services/user.service"
import SiteMember from "@/store/modules/siteMember"
import { AccessLevel } from "@/store/modules/siteMember"
// import UserGroupsEditor from '../components/UserGroupsEditor.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // UserGroupsEditor,
  },
  name: "NewUser",

  beforeMount() {
    const user = this.$route.params.user
    if (user) {
      this.user = user
    }
    if (this.$route.params.userId) {
      const user = this.$route.params.user
      if (user) {
        this.user = user
        UserService.userMemberships(this.user.id).then(access => {
          this.updateGlobalAccess(access)
        })
      } else {
        UserService.get(this.$route.params.userId).then(response => {
          this.user = response.data
          UserService.userMemberships(this.user.id).then(access => {
            this.updateGlobalAccess(access)
          })
        })
      }
    }
  },
  mounted() {
    extend("required", {
      ...required
    })
    extend("email", {
      ...email
    })
    extend("unique", {
      validate: this.isUsernameUnique,
      message: this.$t("Username already taken")
    })
    extend("username", {
      validate: value => /^[\w.@+-]+$/.test(value),
      message:
        "Enter a valid username. This value may contain only English letters, numbers, and @.+-_ characters."
    })
    extend("phone", {
      validate: value => /^\+?[\d ]{4,}$/.test(value),
      message: "Enter a valid phone number"
    })
  },
  beforeDestroy() { },
  computed: {
    validate() {
      return true
    },
    isEdit() {
      return this.user?.id
    },
    title() {
      if (this.isEdit) {
        return "Edycja użytkownika"
      } else {
        return "Nowy użytkownik"
      }
    }
  },
  data: () => ({
    user: {},
    site: null,
    globalMember: false,
    accessLevel: SiteMember.accessLevels(),
    selectedAccessLevel: AccessLevel.EMPLOYEE
  }),
  watch: {
    "user.first_name": function () {
      this.updateUsername()
    },
    "user.last_name": function () {
      this.updateUsername()
    },
    "user.name": function () {
      this.updateUsername()
    }
  },
  methods: {
    async isUsernameUnique() {
      console.log("isunique")
      // try {
      var result = false
      await UserService.fetchUsers(null, [
        `username=${this.user.username}`
      ]).then(data => {
        result =
          data.count == 0 ||
          (data.count == 1 && data.results[0].id == this.user.id)
      })
      return result
      // } catch (err) {
      // }
      // return false
    },
    updateUsername() {
      if (this.user.id) {
        return
      }
      var username = ""
      if (this.user.first_name) {
        username += this.user.first_name
      }
      if (username && this.user.last_name) {
        username += "."
      }
      if (this.user.last_name) {
        username += this.user.last_name
      }
      if (!username && this.user.name) {
        username = this.user.name
      }
      this.user.username = _.deburr(username.toLowerCase())
    },
    siteSelected() { },
    async block() {
      const res = await this.$dialog.confirm({
        text: "Czy na pewno zablokować użytkownika?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Zablokuj"
          }
        }
      })
      if (res === true) {
        this.user.is_active = false
        UserService.createOrUpdateUser(this.user).then(() => {
          this.$router.back()
        })
      }
    },
    async unblock() {
      const res = await this.$dialog.confirm({
        text: "Czy na pewno odblokować użytkownika?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Tak"
          }
        }
      })
      if (res === true) {
        this.user.is_active = true
        UserService.createOrUpdateUser(this.user).then(() => {
          this.$router.back()
        })
      }
    },
    submit() {
      UserService.createOrUpdateUser(this.user)
        .then(() => {
          this.$router.back()
        })
        .catch(error => {
          var message = error.response.data
          if (message.username) {
            // message = `${this.$t("user.login")}: ${message.username[0]}`
            message = message.username[0]
          }
          this.$dialog.notify.warning(message, {
            timeout: 5000
          })
        })
    },
    updateGlobalAccess(access) {
      this.user.access = access
      var global = this.findGlobalAccess(access)
      if (global) {
        this.globalMember = true
        this.selectedAccessLevel = global.access_level
      } else {
        this.globalMember = false
        this.selectedAccessLevel = null
      }
    },
    findGlobalAccess(access) {
      for (const record of access) {
        if (record.site == null) {
          return record
        }
      }
      return null
    }
  }
}
</script>

<style lang="sass">
// .v-card.v-card.v-card--task
//   border-color: currentColor
//   border-width: 4px

//   .v-icon
//     color: inherit

//   .v-card--task,
//   .v-card--task:before
//     border-radius: 50%
//

//.v-text-field--filled .v-input__control .v-input__slot, .v-text-field--full-width . .v-input__slot, .v-text-field--outlined > .v-input__control > . {
//.v-text-field__slot
//  min-height: 20px

</style>
